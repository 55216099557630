import {
  Experience,
  Outlet,
  PrimaryProductAccessType,
  Product,
  ProductCategory,
} from '@collinsonx/utils';
import {
  DEFAULT_CANCELLATION_PERIOD,
  DEFAULT_CONTEXT_PRODUCT_VALUE,
  DEFAULT_CURRENCY,
  DEFAULT_GUESTS_PARAMS,
  DEFAULT_MAX_BOOKING_PERIOD,
  DEFAULT_MAX_PARTY_SIZE,
  DEFAULT_MIN_BOOKING_PERIOD,
  DEFAULT_PRICE,
  DEFAULT_TIME_SLOT_PERIOD,
  GUEST_TYPES,
} from 'config/productDefaultConfig';
import { MappedProductData } from 'types/mappedProductData';

export const convertOutletToProduct = (
  outlet: Outlet,
  product: Pick<Product, 'id' | 'partnerIntegrationData'>
): MappedProductData => {
  const { partnerIntegrationData } = product;
  const productData = outlet.products.filter(
    (productItem) =>
      productItem?.accessType === PrimaryProductAccessType.ReservationFeeOnly
  )[0];

  const saleprice = productData?.salePrices[0]?.salePrice ?? 0;

  const category = productData?.category ?? ProductCategory.Lounge;

  const guests: MappedProductData['guests'] = {};

  if (partnerIntegrationData?.maxGuests?.adult)
    guests[GUEST_TYPES.adults] = {
      ...DEFAULT_GUESTS_PARAMS[GUEST_TYPES.adults],
      isChargeable:
        partnerIntegrationData.maxGuests.adult.isChargeable ??
        DEFAULT_GUESTS_PARAMS.adults.isChargeable,
      isPartySizeImpacted:
        partnerIntegrationData.maxGuests.adult.isPartySizeImpacted,
      maxCountPerGuestType: partnerIntegrationData.maxGuests.adult.value,
    };

  if (partnerIntegrationData?.maxGuests?.children)
    guests[GUEST_TYPES.children] = {
      ...DEFAULT_GUESTS_PARAMS[GUEST_TYPES.children],
      isChargeable:
        partnerIntegrationData.maxGuests.children.isChargeable ??
        DEFAULT_GUESTS_PARAMS.children.isChargeable,
      isPartySizeImpacted:
        partnerIntegrationData.maxGuests.children.isPartySizeImpacted,
      maxCountPerGuestType: partnerIntegrationData.maxGuests.children.value,
    };

  if (partnerIntegrationData?.maxGuests?.infant)
    guests[GUEST_TYPES.infants] = {
      ...DEFAULT_GUESTS_PARAMS[GUEST_TYPES.infants],
      isChargeable:
        partnerIntegrationData.maxGuests.infant.isChargeable ??
        DEFAULT_GUESTS_PARAMS.infants.isChargeable,
      isPartySizeImpacted:
        partnerIntegrationData.maxGuests.infant.isPartySizeImpacted,
      maxCountPerGuestType: partnerIntegrationData.maxGuests.infant.value,
    };

  // TODO: uncomment to enable seniors
  // if(partnerIntegrationData?.maxGuests?.senior) guests[GUEST_TYPES.seniors] = {
  //   ...DEFAULT_GUESTS_PARAMS[GUEST_TYPES.infants],
  //   isPartySizeImpacted: partnerIntegrationData.maxGuests.senior.isPartySizeImpacted,
  //   maxCountPerGuestType: partnerIntegrationData.maxGuests.senior.value,
  // }

  return {
    ...DEFAULT_CONTEXT_PRODUCT_VALUE,
    airportCode: outlet.location.code ?? '',
    airportName: outlet.location.name ?? '',
    bookingFlowType: partnerIntegrationData?.bookingFlowType ?? '',
    bookingMaxPeriodAllowed:
      partnerIntegrationData?.bookingMaxPeriodAllowed ??
      DEFAULT_MAX_BOOKING_PERIOD,
    bookingMinPeriodAllowed:
      partnerIntegrationData?.bookingMinPeriodAllowed ??
      DEFAULT_MIN_BOOKING_PERIOD,
    cancellationPeriod:
      partnerIntegrationData?.cancellationPeriodAllowed ??
      DEFAULT_CANCELLATION_PERIOD,
    category,
    currency:
      outlet.products[0]?.salePrices[0]?.salePriceCurrency ?? DEFAULT_CURRENCY,
    guests,
    imageUrl: outlet.media?.mainImage?.asset?.url ?? '',
    legacyProduct: false,
    loungeCode: outlet.legacyCode ?? '',
    loungeID: outlet.id,
    loungeName: outlet.name,
    maxPartySize:
      partnerIntegrationData?.maxGuests?.partySize ?? DEFAULT_MAX_PARTY_SIZE,
    partnerID:
      outlet.partnerBrand.partnerIntegrationData?.partnerIntegrationID ?? '',
    partnerProductID: partnerIntegrationData?.partnerProductID ?? '',
    productID: product.id,
    saleprice: saleprice ?? DEFAULT_PRICE,
    stripeID: partnerIntegrationData?.stripeProductID || product.id,
    terminal: outlet.location.terminal ?? '',
    timeSlotPeriod:
      partnerIntegrationData?.timeSlotPeriod ?? DEFAULT_TIME_SLOT_PERIOD,
    timezone: outlet.location.timezone ?? '',
    viewOnly: saleprice === 0,
  };
};

export const convertExperienceToProduct = (
  experience: Experience
): MappedProductData => {
  const saleprice = experience.pricing?.reservationOnlyFee;

  return {
    ...DEFAULT_CONTEXT_PRODUCT_VALUE,
    airportCode: experience.location?.airportCode ?? '',
    airportName: experience.location?.airportName ?? '',
    currency: experience.pricing?.currency ?? DEFAULT_CURRENCY,
    imageUrl: experience.images?.[0]?.url ?? '',
    legacyProduct: true,
    loungeCode: experience.loungeCode ?? '',
    loungeID: experience.id,
    loungeName: experience.loungeName ?? '',
    partnerID: experience.partnerIntegrationId ?? '',
    partnerProductID: experience.partnerIdProd ?? '',
    productID: experience.id,
    saleprice: saleprice ?? DEFAULT_PRICE,
    stripeID: experience.id,
    terminal: experience.location?.terminal ?? '',
    timezone: experience.location?.timezone ?? '',
    viewOnly: saleprice === 0,
  };
};
