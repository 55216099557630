import { RefObject, useRef } from 'react';

type UseScrollIntoViewReturnType = [
  targetElement: RefObject<HTMLDivElement>,
  scrollIntoView: () => void
];

const useScrollTo = (): UseScrollIntoViewReturnType => {
  const targetElement = useRef<HTMLDivElement>(null);

  const scrollIntoView = () => {
    targetElement?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  return [targetElement, scrollIntoView];
};

export default useScrollTo;
