"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AcceptInvitationDocument: () => AcceptInvitationDocument,
  ActivationStatus: () => ActivationStatus,
  AddressType: () => AddressType,
  AdmissionPer: () => AdmissionPer,
  AffiliateProgramme: () => AffiliateProgramme,
  AmendmentStatus: () => AmendmentStatus,
  BookingFlowType: () => BookingFlowType,
  BookingStatus: () => BookingStatus,
  BookingType: () => BookingType,
  Brand: () => Brand,
  CancelBookingDocument: () => CancelBookingDocument,
  CatalogueProductType: () => CatalogueProductType,
  CheckinBookingDocument: () => CheckinBookingDocument,
  ConfirmAmendmentDocument: () => ConfirmAmendmentDocument,
  ConfirmBookingDocument: () => ConfirmBookingDocument,
  CostZone: () => CostZone,
  CreateBookingDocument: () => CreateBookingDocument,
  Currency: () => Currency,
  DeclineBookingDocument: () => DeclineBookingDocument,
  DeleteBookingDocument: () => DeleteBookingDocument,
  EnrollmentStatus: () => EnrollmentStatus,
  ExceptionType: () => ExceptionType,
  ExperienceCategory: () => ExperienceCategory,
  ExperienceType: () => ExperienceType,
  FakeDoorInterest: () => FakeDoorInterest,
  FakeDoorOs: () => FakeDoorOs,
  FakeDoorType: () => FakeDoorType,
  FavouriteEntityType: () => FavouriteEntityType,
  FindOrCreateConsumerDocument: () => FindOrCreateConsumerDocument,
  ForecastDocument: () => ForecastDocument,
  GetAvailableSlotsDocument: () => GetAvailableSlotsDocument,
  GetBookingByIdDocument: () => GetBookingByIdDocument,
  GetBookingsDocument: () => GetBookingsDocument,
  GetBookingsOverviewDocument: () => GetBookingsOverviewDocument,
  GetConsumerByEmailAddressDocument: () => GetConsumerByEmailAddressDocument,
  GetConsumerByIdDocument: () => GetConsumerByIdDocument,
  GetConsumerDocument: () => GetConsumerDocument,
  GetEntitlementsForProductDocument: () => GetEntitlementsForProductDocument,
  GetExperienceByIdDocument: () => GetExperienceByIdDocument,
  GetFlightDetailsDocument: () => GetFlightDetailsDocument,
  GetInvitationByIdDocument: () => GetInvitationByIdDocument,
  GetLocationDescriptionDocument: () => GetLocationDescriptionDocument,
  GetOutletDocument: () => GetOutletDocument,
  GetOutletsCountDocument: () => GetOutletsCountDocument,
  GetOutletsDocument: () => GetOutletsDocument,
  GetPartnerBrandByIdDocument: () => GetPartnerBrandByIdDocument,
  GetPartnerBrandsCountDocument: () => GetPartnerBrandsCountDocument,
  GetPartnerBrandsDocument: () => GetPartnerBrandsDocument,
  GetPartnerByIdDocument: () => GetPartnerByIdDocument,
  InvitationUserType: () => InvitationUserType,
  IsInvitationTokenValidDocument: () => IsInvitationTokenValidDocument,
  IsoCountryCode: () => IsoCountryCode,
  JourneyLeg: () => JourneyLeg,
  LinkAccountDocument: () => LinkAccountDocument,
  LinkedAccountProvider: () => LinkedAccountProvider,
  LocationType: () => LocationType,
  MembershipPlanStatus: () => MembershipPlanStatus,
  OutletCategory: () => OutletCategory,
  OutletIdType: () => OutletIdType,
  OutletOpenStatus: () => OutletOpenStatus,
  OutletRegion: () => OutletRegion,
  OutletStatus: () => OutletStatus,
  OutletVersionStatus: () => OutletVersionStatus,
  PackageJourneyLeg: () => PackageJourneyLeg,
  PartnerBrandStatus: () => PartnerBrandStatus,
  PassengerType: () => PassengerType,
  PaymentCustomerStatus: () => PaymentCustomerStatus,
  PaymentOption: () => PaymentOption,
  PaymentProvider: () => PaymentProvider,
  PaymentRefundPriority: () => PaymentRefundPriority,
  PaymentRefundStatusResult: () => PaymentRefundStatusResult,
  PaymentRefundType: () => PaymentRefundType,
  PaymentStatusResult: () => PaymentStatusResult,
  PaymentType: () => PaymentType,
  PrimaryProductAccessType: () => PrimaryProductAccessType,
  ProductCategory: () => ProductCategory,
  ProductCostType: () => ProductCostType,
  ProductIdType: () => ProductIdType,
  ProductStage: () => ProductStage,
  ProductStatus: () => ProductStatus,
  ProductType: () => ProductType,
  Programme: () => Programme,
  PublishOutletDocument: () => PublishOutletDocument,
  RefundStatus: () => RefundStatus,
  RevertOutletDocument: () => RevertOutletDocument,
  SearchExperiencesDocument: () => SearchExperiencesDocument,
  SearchIsoCountryCode: () => SearchIsoCountryCode,
  SearchLocationType: () => SearchLocationType,
  SearchOutletCategory: () => SearchOutletCategory,
  SearchOutletRegion: () => SearchOutletRegion,
  SearchOutletsDocument: () => SearchOutletsDocument,
  SearchType: () => SearchType,
  SlotState: () => SlotState,
  SmokingPolicy: () => SmokingPolicy,
  SortOrder: () => SortOrder,
  Status: () => Status,
  StripeUiMode: () => StripeUiMode,
  Theme: () => Theme,
  Tier: () => Tier,
  TimezoneType: () => TimezoneType,
  TripSource: () => TripSource,
  TripState: () => TripState,
  UpdateConsumerDocument: () => UpdateConsumerDocument,
  UpdateOutletDocument: () => UpdateOutletDocument,
  VariationType: () => VariationType,
  matchingTextColor: () => matchingTextColor,
  randomColor: () => randomColor
});
module.exports = __toCommonJS(src_exports);

// src/generatedTypes/graphql.ts
var ActivationStatus = /* @__PURE__ */ ((ActivationStatus2) => {
  ActivationStatus2["Active"] = "ACTIVE";
  ActivationStatus2["Cancelled"] = "CANCELLED";
  ActivationStatus2["Error"] = "ERROR";
  ActivationStatus2["Redeemed"] = "REDEEMED";
  return ActivationStatus2;
})(ActivationStatus || {});
var AddressType = /* @__PURE__ */ ((AddressType2) => {
  AddressType2["Billing"] = "BILLING";
  AddressType2["Home"] = "HOME";
  return AddressType2;
})(AddressType || {});
var AdmissionPer = /* @__PURE__ */ ((AdmissionPer2) => {
  AdmissionPer2["Adult"] = "adult";
  AdmissionPer2["Cardholder"] = "cardholder";
  return AdmissionPer2;
})(AdmissionPer || {});
var AffiliateProgramme = /* @__PURE__ */ ((AffiliateProgramme2) => {
  AffiliateProgramme2["LoungeKey"] = "LOUNGE_KEY";
  AffiliateProgramme2["LoungePass"] = "LOUNGE_PASS";
  AffiliateProgramme2["Mcae"] = "MCAE";
  AffiliateProgramme2["PriorityPass"] = "PRIORITY_PASS";
  return AffiliateProgramme2;
})(AffiliateProgramme || {});
var AmendmentStatus = /* @__PURE__ */ ((AmendmentStatus2) => {
  AmendmentStatus2["ChargeCompleted"] = "CHARGE_COMPLETED";
  AmendmentStatus2["ChargeFailed"] = "CHARGE_FAILED";
  AmendmentStatus2["ChargeInitialized"] = "CHARGE_INITIALIZED";
  AmendmentStatus2["Confirmed"] = "CONFIRMED";
  AmendmentStatus2["FailedToUpdateBookingRecord"] = "FAILED_TO_UPDATE_BOOKING_RECORD";
  AmendmentStatus2["Initialized"] = "INITIALIZED";
  AmendmentStatus2["RefundFailed"] = "REFUND_FAILED";
  AmendmentStatus2["SnaplogicAmendmentFailed"] = "SNAPLOGIC_AMENDMENT_FAILED";
  AmendmentStatus2["SnaplogicDataIsIncorrect"] = "SNAPLOGIC_DATA_IS_INCORRECT";
  return AmendmentStatus2;
})(AmendmentStatus || {});
var BookingFlowType = /* @__PURE__ */ ((BookingFlowType2) => {
  BookingFlowType2["Integrated"] = "INTEGRATED";
  BookingFlowType2["NonIntegrated"] = "NON_INTEGRATED";
  BookingFlowType2["PreAgreed"] = "PRE_AGREED";
  return BookingFlowType2;
})(BookingFlowType || {});
var BookingStatus = /* @__PURE__ */ ((BookingStatus2) => {
  BookingStatus2["Amended"] = "AMENDED";
  BookingStatus2["Booked"] = "BOOKED";
  BookingStatus2["CancelationFailed"] = "CANCELATION_FAILED";
  BookingStatus2["Cancelled"] = "CANCELLED";
  BookingStatus2["CheckedIn"] = "CHECKED_IN";
  BookingStatus2["CompletedVisit"] = "COMPLETED_VISIT";
  BookingStatus2["Confirmed"] = "CONFIRMED";
  BookingStatus2["Declined"] = "DECLINED";
  BookingStatus2["Errored"] = "ERRORED";
  BookingStatus2["Initialized"] = "INITIALIZED";
  BookingStatus2["NoShow"] = "NO_SHOW";
  BookingStatus2["Pending"] = "PENDING";
  return BookingStatus2;
})(BookingStatus || {});
var BookingType = /* @__PURE__ */ ((BookingType2) => {
  BookingType2["Reservation"] = "RESERVATION";
  BookingType2["ReservationFeeOnly"] = "RESERVATION_FEE_ONLY";
  BookingType2["WalkUp"] = "WALK_UP";
  return BookingType2;
})(BookingType || {});
var Brand = /* @__PURE__ */ ((Brand2) => {
  Brand2["Affirm"] = "AFFIRM";
  Brand2["Airplus"] = "AIRPLUS";
  Brand2["Alia"] = "ALIA";
  Brand2["Aliadebit"] = "ALIADEBIT";
  Brand2["Amex"] = "AMEX";
  Brand2["Applepay"] = "APPLEPAY";
  Brand2["Argencard"] = "ARGENCARD";
  Brand2["Axp"] = "AXP";
  Brand2["Bcmc"] = "BCMC";
  Brand2["Cabal"] = "CABAL";
  Brand2["Cabaldebit"] = "CABALDEBIT";
  Brand2["Carnet"] = "CARNET";
  Brand2["Cartebancaire"] = "CARTEBANCAIRE";
  Brand2["Cartebleue"] = "CARTEBLEUE";
  Brand2["Cashlinkmalta"] = "CASHLINKMALTA";
  Brand2["Cencosud"] = "CENCOSUD";
  Brand2["ClickToPay"] = "CLICK_TO_PAY";
  Brand2["Dankort"] = "DANKORT";
  Brand2["Diners"] = "DINERS";
  Brand2["Discover"] = "DISCOVER";
  Brand2["Elo"] = "ELO";
  Brand2["Facilypay_3X"] = "FACILYPAY_3X";
  Brand2["Facilypay_3Xsansfrais"] = "FACILYPAY_3XSANSFRAIS";
  Brand2["Facilypay_4X"] = "FACILYPAY_4X";
  Brand2["Facilypay_4Xsansfrais"] = "FACILYPAY_4XSANSFRAIS";
  Brand2["Googlepay"] = "GOOGLEPAY";
  Brand2["HebGiftCard"] = "HEB_GIFT_CARD";
  Brand2["Hipercard"] = "HIPERCARD";
  Brand2["Jcb"] = "JCB";
  Brand2["Mada"] = "MADA";
  Brand2["Maestro"] = "MAESTRO";
  Brand2["Master"] = "MASTER";
  Brand2["Mastercard"] = "MASTERCARD";
  Brand2["Masterdebit"] = "MASTERDEBIT";
  Brand2["Meeza"] = "MEEZA";
  Brand2["Mercadolivre"] = "MERCADOLIVRE";
  Brand2["Naranja"] = "NARANJA";
  Brand2["Nativa"] = "NATIVA";
  Brand2["PetcoMastercard"] = "PETCO_MASTERCARD";
  Brand2["PetcoUplcc"] = "PETCO_UPLCC";
  Brand2["RbcPayplan"] = "RBC_PAYPLAN";
  Brand2["Samsungpay"] = "SAMSUNGPAY";
  Brand2["Scheels"] = "SCHEELS";
  Brand2["Servired"] = "SERVIRED";
  Brand2["Sistemaclave"] = "SISTEMACLAVE";
  Brand2["Staples"] = "STAPLES";
  Brand2["Tarjetashopping"] = "TARJETASHOPPING";
  Brand2["Tcard"] = "TCARD";
  Brand2["Tcarddebit"] = "TCARDDEBIT";
  Brand2["TradeUk"] = "TRADE_UK";
  Brand2["Unionpay"] = "UNIONPAY";
  Brand2["UnionpaySms"] = "UNIONPAY_SMS";
  Brand2["Visa"] = "VISA";
  Brand2["Visadebit"] = "VISADEBIT";
  Brand2["Visaelectron"] = "VISAELECTRON";
  Brand2["Vpay"] = "VPAY";
  return Brand2;
})(Brand || {});
var CatalogueProductType = /* @__PURE__ */ ((CatalogueProductType2) => {
  CatalogueProductType2["Affiliate"] = "AFFILIATE";
  CatalogueProductType2["Ancillary"] = "ANCILLARY";
  CatalogueProductType2["Package"] = "PACKAGE";
  CatalogueProductType2["Primary"] = "PRIMARY";
  return CatalogueProductType2;
})(CatalogueProductType || {});
var CostZone = /* @__PURE__ */ ((CostZone2) => {
  CostZone2["CostZoneA"] = "COST_ZONE_A";
  CostZone2["CostZoneB"] = "COST_ZONE_B";
  CostZone2["CostZoneC"] = "COST_ZONE_C";
  return CostZone2;
})(CostZone || {});
var Currency = /* @__PURE__ */ ((Currency2) => {
  Currency2["Eur"] = "EUR";
  Currency2["Gbp"] = "GBP";
  Currency2["Inr"] = "INR";
  Currency2["Usd"] = "USD";
  return Currency2;
})(Currency || {});
var EnrollmentStatus = /* @__PURE__ */ ((EnrollmentStatus2) => {
  EnrollmentStatus2["Active"] = "ACTIVE";
  EnrollmentStatus2["Blocked"] = "BLOCKED";
  EnrollmentStatus2["Hold"] = "HOLD";
  return EnrollmentStatus2;
})(EnrollmentStatus || {});
var ExceptionType = /* @__PURE__ */ ((ExceptionType2) => {
  ExceptionType2["DatePeriod"] = "DATE_PERIOD";
  ExceptionType2["RecurringDate"] = "RECURRING_DATE";
  ExceptionType2["RecurringDatePeriod"] = "RECURRING_DATE_PERIOD";
  ExceptionType2["SpecificDate"] = "SPECIFIC_DATE";
  return ExceptionType2;
})(ExceptionType || {});
var ExperienceCategory = /* @__PURE__ */ ((ExperienceCategory2) => {
  ExperienceCategory2["AirportExperience"] = "AIRPORT_EXPERIENCE";
  return ExperienceCategory2;
})(ExperienceCategory || {});
var ExperienceType = /* @__PURE__ */ ((ExperienceType2) => {
  ExperienceType2["Lounge"] = "LOUNGE";
  return ExperienceType2;
})(ExperienceType || {});
var FakeDoorInterest = /* @__PURE__ */ ((FakeDoorInterest2) => {
  FakeDoorInterest2["DoNotKnow"] = "DO_NOT_KNOW";
  FakeDoorInterest2["Interested"] = "INTERESTED";
  FakeDoorInterest2["NotInterested"] = "NOT_INTERESTED";
  return FakeDoorInterest2;
})(FakeDoorInterest || {});
var FakeDoorOs = /* @__PURE__ */ ((FakeDoorOs2) => {
  FakeDoorOs2["Android"] = "ANDROID";
  FakeDoorOs2["Ios"] = "IOS";
  return FakeDoorOs2;
})(FakeDoorOs || {});
var FakeDoorType = /* @__PURE__ */ ((FakeDoorType2) => {
  FakeDoorType2["TripForwardEmail"] = "TRIP_FORWARD_EMAIL";
  FakeDoorType2["TripInboxSync"] = "TRIP_INBOX_SYNC";
  return FakeDoorType2;
})(FakeDoorType || {});
var FavouriteEntityType = /* @__PURE__ */ ((FavouriteEntityType2) => {
  FavouriteEntityType2["Airport"] = "airport";
  FavouriteEntityType2["Outlet"] = "outlet";
  return FavouriteEntityType2;
})(FavouriteEntityType || {});
var IsoCountryCode = /* @__PURE__ */ ((IsoCountryCode2) => {
  IsoCountryCode2["Abw"] = "ABW";
  IsoCountryCode2["Afg"] = "AFG";
  IsoCountryCode2["Ago"] = "AGO";
  IsoCountryCode2["Aia"] = "AIA";
  IsoCountryCode2["Ala"] = "ALA";
  IsoCountryCode2["Alb"] = "ALB";
  IsoCountryCode2["And"] = "AND";
  IsoCountryCode2["Ant"] = "ANT";
  IsoCountryCode2["Are"] = "ARE";
  IsoCountryCode2["Arg"] = "ARG";
  IsoCountryCode2["Arm"] = "ARM";
  IsoCountryCode2["Asm"] = "ASM";
  IsoCountryCode2["Ata"] = "ATA";
  IsoCountryCode2["Atf"] = "ATF";
  IsoCountryCode2["Atg"] = "ATG";
  IsoCountryCode2["Aus"] = "AUS";
  IsoCountryCode2["Aut"] = "AUT";
  IsoCountryCode2["Aze"] = "AZE";
  IsoCountryCode2["Bdi"] = "BDI";
  IsoCountryCode2["Bel"] = "BEL";
  IsoCountryCode2["Ben"] = "BEN";
  IsoCountryCode2["Bes"] = "BES";
  IsoCountryCode2["Bfa"] = "BFA";
  IsoCountryCode2["Bgd"] = "BGD";
  IsoCountryCode2["Bgr"] = "BGR";
  IsoCountryCode2["Bhr"] = "BHR";
  IsoCountryCode2["Bhs"] = "BHS";
  IsoCountryCode2["Bih"] = "BIH";
  IsoCountryCode2["Blm"] = "BLM";
  IsoCountryCode2["Blr"] = "BLR";
  IsoCountryCode2["Blz"] = "BLZ";
  IsoCountryCode2["Bmu"] = "BMU";
  IsoCountryCode2["Bol"] = "BOL";
  IsoCountryCode2["Bra"] = "BRA";
  IsoCountryCode2["Brb"] = "BRB";
  IsoCountryCode2["Brn"] = "BRN";
  IsoCountryCode2["Btn"] = "BTN";
  IsoCountryCode2["Bvt"] = "BVT";
  IsoCountryCode2["Bwa"] = "BWA";
  IsoCountryCode2["Caf"] = "CAF";
  IsoCountryCode2["Can"] = "CAN";
  IsoCountryCode2["Cck"] = "CCK";
  IsoCountryCode2["Che"] = "CHE";
  IsoCountryCode2["Chl"] = "CHL";
  IsoCountryCode2["Chn"] = "CHN";
  IsoCountryCode2["Civ"] = "CIV";
  IsoCountryCode2["Cmr"] = "CMR";
  IsoCountryCode2["Cod"] = "COD";
  IsoCountryCode2["Cog"] = "COG";
  IsoCountryCode2["Cok"] = "COK";
  IsoCountryCode2["Col"] = "COL";
  IsoCountryCode2["Com"] = "COM";
  IsoCountryCode2["Cpv"] = "CPV";
  IsoCountryCode2["Cri"] = "CRI";
  IsoCountryCode2["Cub"] = "CUB";
  IsoCountryCode2["Cuw"] = "CUW";
  IsoCountryCode2["Cxr"] = "CXR";
  IsoCountryCode2["Cym"] = "CYM";
  IsoCountryCode2["Cyp"] = "CYP";
  IsoCountryCode2["Cze"] = "CZE";
  IsoCountryCode2["Deu"] = "DEU";
  IsoCountryCode2["Dji"] = "DJI";
  IsoCountryCode2["Dma"] = "DMA";
  IsoCountryCode2["Dnk"] = "DNK";
  IsoCountryCode2["Dom"] = "DOM";
  IsoCountryCode2["Dza"] = "DZA";
  IsoCountryCode2["Ecu"] = "ECU";
  IsoCountryCode2["Egy"] = "EGY";
  IsoCountryCode2["Eri"] = "ERI";
  IsoCountryCode2["Esh"] = "ESH";
  IsoCountryCode2["Esp"] = "ESP";
  IsoCountryCode2["Est"] = "EST";
  IsoCountryCode2["Eth"] = "ETH";
  IsoCountryCode2["Fin"] = "FIN";
  IsoCountryCode2["Fji"] = "FJI";
  IsoCountryCode2["Flk"] = "FLK";
  IsoCountryCode2["Fra"] = "FRA";
  IsoCountryCode2["Fro"] = "FRO";
  IsoCountryCode2["Fsm"] = "FSM";
  IsoCountryCode2["Gab"] = "GAB";
  IsoCountryCode2["Gbr"] = "GBR";
  IsoCountryCode2["Geo"] = "GEO";
  IsoCountryCode2["Ggy"] = "GGY";
  IsoCountryCode2["Gha"] = "GHA";
  IsoCountryCode2["Gib"] = "GIB";
  IsoCountryCode2["Gin"] = "GIN";
  IsoCountryCode2["Glp"] = "GLP";
  IsoCountryCode2["Gmb"] = "GMB";
  IsoCountryCode2["Gnb"] = "GNB";
  IsoCountryCode2["Gnq"] = "GNQ";
  IsoCountryCode2["Grc"] = "GRC";
  IsoCountryCode2["Grd"] = "GRD";
  IsoCountryCode2["Grl"] = "GRL";
  IsoCountryCode2["Gtm"] = "GTM";
  IsoCountryCode2["Guf"] = "GUF";
  IsoCountryCode2["Gum"] = "GUM";
  IsoCountryCode2["Guy"] = "GUY";
  IsoCountryCode2["Hkg"] = "HKG";
  IsoCountryCode2["Hmd"] = "HMD";
  IsoCountryCode2["Hnd"] = "HND";
  IsoCountryCode2["Hrv"] = "HRV";
  IsoCountryCode2["Hti"] = "HTI";
  IsoCountryCode2["Hun"] = "HUN";
  IsoCountryCode2["Idn"] = "IDN";
  IsoCountryCode2["Imn"] = "IMN";
  IsoCountryCode2["Ind"] = "IND";
  IsoCountryCode2["Iot"] = "IOT";
  IsoCountryCode2["Irl"] = "IRL";
  IsoCountryCode2["Irn"] = "IRN";
  IsoCountryCode2["Irq"] = "IRQ";
  IsoCountryCode2["Isl"] = "ISL";
  IsoCountryCode2["Isr"] = "ISR";
  IsoCountryCode2["Ita"] = "ITA";
  IsoCountryCode2["Jam"] = "JAM";
  IsoCountryCode2["Jey"] = "JEY";
  IsoCountryCode2["Jor"] = "JOR";
  IsoCountryCode2["Jpn"] = "JPN";
  IsoCountryCode2["Kaz"] = "KAZ";
  IsoCountryCode2["Ken"] = "KEN";
  IsoCountryCode2["Kgz"] = "KGZ";
  IsoCountryCode2["Khm"] = "KHM";
  IsoCountryCode2["Kir"] = "KIR";
  IsoCountryCode2["Kna"] = "KNA";
  IsoCountryCode2["Kor"] = "KOR";
  IsoCountryCode2["Kwt"] = "KWT";
  IsoCountryCode2["Lao"] = "LAO";
  IsoCountryCode2["Lbn"] = "LBN";
  IsoCountryCode2["Lbr"] = "LBR";
  IsoCountryCode2["Lby"] = "LBY";
  IsoCountryCode2["Lca"] = "LCA";
  IsoCountryCode2["Lie"] = "LIE";
  IsoCountryCode2["Lka"] = "LKA";
  IsoCountryCode2["Lso"] = "LSO";
  IsoCountryCode2["Ltu"] = "LTU";
  IsoCountryCode2["Lux"] = "LUX";
  IsoCountryCode2["Lva"] = "LVA";
  IsoCountryCode2["Mac"] = "MAC";
  IsoCountryCode2["Maf"] = "MAF";
  IsoCountryCode2["Mar"] = "MAR";
  IsoCountryCode2["Mco"] = "MCO";
  IsoCountryCode2["Mda"] = "MDA";
  IsoCountryCode2["Mdg"] = "MDG";
  IsoCountryCode2["Mdv"] = "MDV";
  IsoCountryCode2["Mex"] = "MEX";
  IsoCountryCode2["Mhl"] = "MHL";
  IsoCountryCode2["Mkd"] = "MKD";
  IsoCountryCode2["Mli"] = "MLI";
  IsoCountryCode2["Mlt"] = "MLT";
  IsoCountryCode2["Mmr"] = "MMR";
  IsoCountryCode2["Mne"] = "MNE";
  IsoCountryCode2["Mng"] = "MNG";
  IsoCountryCode2["Mnp"] = "MNP";
  IsoCountryCode2["Moz"] = "MOZ";
  IsoCountryCode2["Mrt"] = "MRT";
  IsoCountryCode2["Msr"] = "MSR";
  IsoCountryCode2["Mtq"] = "MTQ";
  IsoCountryCode2["Mus"] = "MUS";
  IsoCountryCode2["Mwi"] = "MWI";
  IsoCountryCode2["Mys"] = "MYS";
  IsoCountryCode2["Myt"] = "MYT";
  IsoCountryCode2["Nam"] = "NAM";
  IsoCountryCode2["Ncl"] = "NCL";
  IsoCountryCode2["Ner"] = "NER";
  IsoCountryCode2["Nfk"] = "NFK";
  IsoCountryCode2["Nga"] = "NGA";
  IsoCountryCode2["Nic"] = "NIC";
  IsoCountryCode2["Niu"] = "NIU";
  IsoCountryCode2["Nld"] = "NLD";
  IsoCountryCode2["Nor"] = "NOR";
  IsoCountryCode2["Npl"] = "NPL";
  IsoCountryCode2["Nru"] = "NRU";
  IsoCountryCode2["Nzl"] = "NZL";
  IsoCountryCode2["Omn"] = "OMN";
  IsoCountryCode2["Pak"] = "PAK";
  IsoCountryCode2["Pan"] = "PAN";
  IsoCountryCode2["Pcn"] = "PCN";
  IsoCountryCode2["Per"] = "PER";
  IsoCountryCode2["Phl"] = "PHL";
  IsoCountryCode2["Plw"] = "PLW";
  IsoCountryCode2["Png"] = "PNG";
  IsoCountryCode2["Pol"] = "POL";
  IsoCountryCode2["Pri"] = "PRI";
  IsoCountryCode2["Prk"] = "PRK";
  IsoCountryCode2["Prt"] = "PRT";
  IsoCountryCode2["Pry"] = "PRY";
  IsoCountryCode2["Pse"] = "PSE";
  IsoCountryCode2["Pyf"] = "PYF";
  IsoCountryCode2["Qat"] = "QAT";
  IsoCountryCode2["Reu"] = "REU";
  IsoCountryCode2["Rou"] = "ROU";
  IsoCountryCode2["Rus"] = "RUS";
  IsoCountryCode2["Rwa"] = "RWA";
  IsoCountryCode2["Sau"] = "SAU";
  IsoCountryCode2["Sdn"] = "SDN";
  IsoCountryCode2["Sen"] = "SEN";
  IsoCountryCode2["Sgp"] = "SGP";
  IsoCountryCode2["Sgs"] = "SGS";
  IsoCountryCode2["Shn"] = "SHN";
  IsoCountryCode2["Sjm"] = "SJM";
  IsoCountryCode2["Slb"] = "SLB";
  IsoCountryCode2["Sle"] = "SLE";
  IsoCountryCode2["Slv"] = "SLV";
  IsoCountryCode2["Smr"] = "SMR";
  IsoCountryCode2["Som"] = "SOM";
  IsoCountryCode2["Spm"] = "SPM";
  IsoCountryCode2["Srb"] = "SRB";
  IsoCountryCode2["Ssd"] = "SSD";
  IsoCountryCode2["Stp"] = "STP";
  IsoCountryCode2["Sur"] = "SUR";
  IsoCountryCode2["Svk"] = "SVK";
  IsoCountryCode2["Svn"] = "SVN";
  IsoCountryCode2["Swe"] = "SWE";
  IsoCountryCode2["Swz"] = "SWZ";
  IsoCountryCode2["Sxm"] = "SXM";
  IsoCountryCode2["Syc"] = "SYC";
  IsoCountryCode2["Syr"] = "SYR";
  IsoCountryCode2["Tca"] = "TCA";
  IsoCountryCode2["Tcd"] = "TCD";
  IsoCountryCode2["Tgo"] = "TGO";
  IsoCountryCode2["Tha"] = "THA";
  IsoCountryCode2["Tjk"] = "TJK";
  IsoCountryCode2["Tkl"] = "TKL";
  IsoCountryCode2["Tkm"] = "TKM";
  IsoCountryCode2["Tls"] = "TLS";
  IsoCountryCode2["Ton"] = "TON";
  IsoCountryCode2["Tto"] = "TTO";
  IsoCountryCode2["Tun"] = "TUN";
  IsoCountryCode2["Tur"] = "TUR";
  IsoCountryCode2["Tuv"] = "TUV";
  IsoCountryCode2["Twn"] = "TWN";
  IsoCountryCode2["Tza"] = "TZA";
  IsoCountryCode2["Uga"] = "UGA";
  IsoCountryCode2["Ukr"] = "UKR";
  IsoCountryCode2["Umi"] = "UMI";
  IsoCountryCode2["Ury"] = "URY";
  IsoCountryCode2["Usa"] = "USA";
  IsoCountryCode2["Uzb"] = "UZB";
  IsoCountryCode2["Vat"] = "VAT";
  IsoCountryCode2["Vct"] = "VCT";
  IsoCountryCode2["Ven"] = "VEN";
  IsoCountryCode2["Vgb"] = "VGB";
  IsoCountryCode2["Vir"] = "VIR";
  IsoCountryCode2["Vnm"] = "VNM";
  IsoCountryCode2["Vut"] = "VUT";
  IsoCountryCode2["Wlf"] = "WLF";
  IsoCountryCode2["Wsm"] = "WSM";
  IsoCountryCode2["Yem"] = "YEM";
  IsoCountryCode2["Zaf"] = "ZAF";
  IsoCountryCode2["Zmb"] = "ZMB";
  IsoCountryCode2["Zwe"] = "ZWE";
  return IsoCountryCode2;
})(IsoCountryCode || {});
var InvitationUserType = /* @__PURE__ */ ((InvitationUserType2) => {
  InvitationUserType2["OutletManager"] = "OUTLET_MANAGER";
  InvitationUserType2["Partner"] = "PARTNER";
  InvitationUserType2["SuperUser"] = "SUPER_USER";
  return InvitationUserType2;
})(InvitationUserType || {});
var JourneyLeg = /* @__PURE__ */ ((JourneyLeg2) => {
  JourneyLeg2["Arrival"] = "ARRIVAL";
  JourneyLeg2["Departure"] = "DEPARTURE";
  return JourneyLeg2;
})(JourneyLeg || {});
var LinkedAccountProvider = /* @__PURE__ */ ((LinkedAccountProvider2) => {
  LinkedAccountProvider2["LoungeKey"] = "LOUNGE_KEY";
  LinkedAccountProvider2["PriorityPass"] = "PRIORITY_PASS";
  return LinkedAccountProvider2;
})(LinkedAccountProvider || {});
var LocationType = /* @__PURE__ */ ((LocationType2) => {
  LocationType2["Airport"] = "AIRPORT";
  LocationType2["FerryStation"] = "FERRY_STATION";
  LocationType2["RailwayStation"] = "RAILWAY_STATION";
  return LocationType2;
})(LocationType || {});
var MembershipPlanStatus = /* @__PURE__ */ ((MembershipPlanStatus2) => {
  MembershipPlanStatus2["Active"] = "Active";
  MembershipPlanStatus2["Cancelled"] = "Cancelled";
  MembershipPlanStatus2["Defunct"] = "Defunct";
  MembershipPlanStatus2["Draft"] = "Draft";
  MembershipPlanStatus2["Inactive"] = "Inactive";
  MembershipPlanStatus2["Pipeline"] = "Pipeline";
  return MembershipPlanStatus2;
})(MembershipPlanStatus || {});
var OutletCategory = /* @__PURE__ */ ((OutletCategory2) => {
  OutletCategory2["Eat"] = "EAT";
  OutletCategory2["FastTrack"] = "FAST_TRACK";
  OutletCategory2["Lounge"] = "LOUNGE";
  OutletCategory2["Rail"] = "RAIL";
  OutletCategory2["Refresh"] = "REFRESH";
  OutletCategory2["Rest"] = "REST";
  OutletCategory2["Retail"] = "RETAIL";
  OutletCategory2["Unwind"] = "UNWIND";
  return OutletCategory2;
})(OutletCategory || {});
var OutletOpenStatus = /* @__PURE__ */ ((OutletOpenStatus2) => {
  OutletOpenStatus2["Closed"] = "CLOSED";
  OutletOpenStatus2["Open"] = "OPEN";
  OutletOpenStatus2["Unavailable"] = "UNAVAILABLE";
  return OutletOpenStatus2;
})(OutletOpenStatus || {});
var OutletRegion = /* @__PURE__ */ ((OutletRegion2) => {
  OutletRegion2["Americas"] = "AMERICAS";
  OutletRegion2["Apac"] = "APAC";
  OutletRegion2["Emea"] = "EMEA";
  OutletRegion2["Global"] = "GLOBAL";
  return OutletRegion2;
})(OutletRegion || {});
var OutletStatus = /* @__PURE__ */ ((OutletStatus2) => {
  OutletStatus2["Active"] = "ACTIVE";
  OutletStatus2["Closed"] = "CLOSED";
  OutletStatus2["Inactive"] = "INACTIVE";
  OutletStatus2["Live"] = "LIVE";
  OutletStatus2["Old"] = "OLD";
  OutletStatus2["Onboarding"] = "ONBOARDING";
  OutletStatus2["StopSale"] = "STOP_SALE";
  return OutletStatus2;
})(OutletStatus || {});
var OutletVersionStatus = /* @__PURE__ */ ((OutletVersionStatus2) => {
  OutletVersionStatus2["Draft"] = "DRAFT";
  OutletVersionStatus2["Published"] = "PUBLISHED";
  return OutletVersionStatus2;
})(OutletVersionStatus || {});
var PackageJourneyLeg = /* @__PURE__ */ ((PackageJourneyLeg2) => {
  PackageJourneyLeg2["Arrival"] = "ARRIVAL";
  PackageJourneyLeg2["Departure"] = "DEPARTURE";
  return PackageJourneyLeg2;
})(PackageJourneyLeg || {});
var PartnerBrandStatus = /* @__PURE__ */ ((PartnerBrandStatus2) => {
  PartnerBrandStatus2["Live"] = "LIVE";
  PartnerBrandStatus2["Old"] = "OLD";
  PartnerBrandStatus2["Onboarding"] = "ONBOARDING";
  PartnerBrandStatus2["RelationshipEnded"] = "RELATIONSHIP_ENDED";
  PartnerBrandStatus2["RelationshipLive"] = "RELATIONSHIP_LIVE";
  return PartnerBrandStatus2;
})(PartnerBrandStatus || {});
var PassengerType = /* @__PURE__ */ ((PassengerType2) => {
  PassengerType2["Both"] = "BOTH";
  PassengerType2["Domestic"] = "DOMESTIC";
  PassengerType2["International"] = "INTERNATIONAL";
  PassengerType2["NonSchengen"] = "NON_SCHENGEN";
  PassengerType2["Schengen"] = "SCHENGEN";
  PassengerType2["Unknown"] = "UNKNOWN";
  PassengerType2["UsTransborder"] = "US_TRANSBORDER";
  return PassengerType2;
})(PassengerType || {});
var PaymentCustomerStatus = /* @__PURE__ */ ((PaymentCustomerStatus2) => {
  PaymentCustomerStatus2["Existing"] = "EXISTING";
  PaymentCustomerStatus2["New"] = "NEW";
  return PaymentCustomerStatus2;
})(PaymentCustomerStatus || {});
var PaymentOption = /* @__PURE__ */ ((PaymentOption2) => {
  PaymentOption2["Charge"] = "Charge";
  PaymentOption2["NoPaymentRequired"] = "NoPaymentRequired";
  PaymentOption2["Refund"] = "Refund";
  return PaymentOption2;
})(PaymentOption || {});
var PaymentProvider = /* @__PURE__ */ ((PaymentProvider2) => {
  PaymentProvider2["Aci"] = "ACI";
  PaymentProvider2["Cashfree"] = "CASHFREE";
  return PaymentProvider2;
})(PaymentProvider || {});
var PaymentRefundPriority = /* @__PURE__ */ ((PaymentRefundPriority2) => {
  PaymentRefundPriority2["Instant"] = "INSTANT";
  PaymentRefundPriority2["Standard"] = "STANDARD";
  return PaymentRefundPriority2;
})(PaymentRefundPriority || {});
var PaymentRefundStatusResult = /* @__PURE__ */ ((PaymentRefundStatusResult2) => {
  PaymentRefundStatusResult2["Cancelled"] = "CANCELLED";
  PaymentRefundStatusResult2["Failed"] = "FAILED";
  PaymentRefundStatusResult2["Onhold"] = "ONHOLD";
  PaymentRefundStatusResult2["Pending"] = "PENDING";
  PaymentRefundStatusResult2["Success"] = "SUCCESS";
  return PaymentRefundStatusResult2;
})(PaymentRefundStatusResult || {});
var PaymentRefundType = /* @__PURE__ */ ((PaymentRefundType2) => {
  PaymentRefundType2["Full"] = "FULL";
  PaymentRefundType2["Partial"] = "PARTIAL";
  return PaymentRefundType2;
})(PaymentRefundType || {});
var PaymentStatusResult = /* @__PURE__ */ ((PaymentStatusResult2) => {
  PaymentStatusResult2["Rejected"] = "REJECTED";
  PaymentStatusResult2["Success"] = "SUCCESS";
  return PaymentStatusResult2;
})(PaymentStatusResult || {});
var PaymentType = /* @__PURE__ */ ((PaymentType2) => {
  PaymentType2["Capture"] = "CAPTURE";
  PaymentType2["Credit"] = "CREDIT";
  PaymentType2["Debit"] = "DEBIT";
  PaymentType2["Preauthorization"] = "PREAUTHORIZATION";
  PaymentType2["Refund"] = "REFUND";
  PaymentType2["Reversal"] = "REVERSAL";
  return PaymentType2;
})(PaymentType || {});
var PrimaryProductAccessType = /* @__PURE__ */ ((PrimaryProductAccessType2) => {
  PrimaryProductAccessType2["Reservation"] = "RESERVATION";
  PrimaryProductAccessType2["ReservationFeeOnly"] = "RESERVATION_FEE_ONLY";
  PrimaryProductAccessType2["WalkUp"] = "WALK_UP";
  return PrimaryProductAccessType2;
})(PrimaryProductAccessType || {});
var ProductCategory = /* @__PURE__ */ ((ProductCategory2) => {
  ProductCategory2["Eat"] = "EAT";
  ProductCategory2["FastTrack"] = "FAST_TRACK";
  ProductCategory2["Lounge"] = "LOUNGE";
  ProductCategory2["Rail"] = "RAIL";
  ProductCategory2["Refresh"] = "REFRESH";
  ProductCategory2["Rest"] = "REST";
  ProductCategory2["Unwind"] = "UNWIND";
  return ProductCategory2;
})(ProductCategory || {});
var ProductCostType = /* @__PURE__ */ ((ProductCostType2) => {
  ProductCostType2["Flat"] = "FLAT";
  ProductCostType2["Tiered"] = "TIERED";
  return ProductCostType2;
})(ProductCostType || {});
var ProductIdType = /* @__PURE__ */ ((ProductIdType2) => {
  ProductIdType2["Id"] = "Id";
  ProductIdType2["PartnerProductId"] = "partnerProductID";
  ProductIdType2["SalesforceId"] = "salesforceID";
  return ProductIdType2;
})(ProductIdType || {});
var ProductStage = /* @__PURE__ */ ((ProductStage2) => {
  ProductStage2["Closed"] = "CLOSED";
  ProductStage2["Declined"] = "DECLINED";
  ProductStage2["Draft"] = "DRAFT";
  ProductStage2["Live"] = "LIVE";
  ProductStage2["Onboarding"] = "ONBOARDING";
  return ProductStage2;
})(ProductStage || {});
var ProductStatus = /* @__PURE__ */ ((ProductStatus2) => {
  ProductStatus2["Active"] = "ACTIVE";
  ProductStatus2["Inactive"] = "INACTIVE";
  ProductStatus2["Live"] = "LIVE";
  ProductStatus2["Open"] = "OPEN";
  ProductStatus2["StopSale"] = "STOP_SALE";
  return ProductStatus2;
})(ProductStatus || {});
var ProductType = /* @__PURE__ */ ((ProductType2) => {
  ProductType2["Lounge"] = "Lounge";
  return ProductType2;
})(ProductType || {});
var Programme = /* @__PURE__ */ ((Programme2) => {
  Programme2["LoungeKey"] = "LOUNGE_KEY";
  Programme2["LoungePass"] = "LOUNGE_PASS";
  Programme2["PpPrivate"] = "PP_PRIVATE";
  Programme2["PriorityPass"] = "PRIORITY_PASS";
  return Programme2;
})(Programme || {});
var RefundStatus = /* @__PURE__ */ ((RefundStatus2) => {
  RefundStatus2["Canceled"] = "CANCELED";
  RefundStatus2["Failed"] = "FAILED";
  RefundStatus2["NotApplicable"] = "NOT_APPLICABLE";
  RefundStatus2["Pending"] = "PENDING";
  RefundStatus2["RequiresAction"] = "REQUIRES_ACTION";
  RefundStatus2["Succeeded"] = "SUCCEEDED";
  return RefundStatus2;
})(RefundStatus || {});
var SearchIsoCountryCode = /* @__PURE__ */ ((SearchIsoCountryCode2) => {
  SearchIsoCountryCode2["Abw"] = "ABW";
  SearchIsoCountryCode2["Afg"] = "AFG";
  SearchIsoCountryCode2["Ago"] = "AGO";
  SearchIsoCountryCode2["Aia"] = "AIA";
  SearchIsoCountryCode2["Ala"] = "ALA";
  SearchIsoCountryCode2["Alb"] = "ALB";
  SearchIsoCountryCode2["And"] = "AND";
  SearchIsoCountryCode2["Ant"] = "ANT";
  SearchIsoCountryCode2["Are"] = "ARE";
  SearchIsoCountryCode2["Arg"] = "ARG";
  SearchIsoCountryCode2["Arm"] = "ARM";
  SearchIsoCountryCode2["Asm"] = "ASM";
  SearchIsoCountryCode2["Ata"] = "ATA";
  SearchIsoCountryCode2["Atf"] = "ATF";
  SearchIsoCountryCode2["Atg"] = "ATG";
  SearchIsoCountryCode2["Aus"] = "AUS";
  SearchIsoCountryCode2["Aut"] = "AUT";
  SearchIsoCountryCode2["Aze"] = "AZE";
  SearchIsoCountryCode2["Bdi"] = "BDI";
  SearchIsoCountryCode2["Bel"] = "BEL";
  SearchIsoCountryCode2["Ben"] = "BEN";
  SearchIsoCountryCode2["Bes"] = "BES";
  SearchIsoCountryCode2["Bfa"] = "BFA";
  SearchIsoCountryCode2["Bgd"] = "BGD";
  SearchIsoCountryCode2["Bgr"] = "BGR";
  SearchIsoCountryCode2["Bhr"] = "BHR";
  SearchIsoCountryCode2["Bhs"] = "BHS";
  SearchIsoCountryCode2["Bih"] = "BIH";
  SearchIsoCountryCode2["Blm"] = "BLM";
  SearchIsoCountryCode2["Blr"] = "BLR";
  SearchIsoCountryCode2["Blz"] = "BLZ";
  SearchIsoCountryCode2["Bmu"] = "BMU";
  SearchIsoCountryCode2["Bol"] = "BOL";
  SearchIsoCountryCode2["Bra"] = "BRA";
  SearchIsoCountryCode2["Brb"] = "BRB";
  SearchIsoCountryCode2["Brn"] = "BRN";
  SearchIsoCountryCode2["Btn"] = "BTN";
  SearchIsoCountryCode2["Bvt"] = "BVT";
  SearchIsoCountryCode2["Bwa"] = "BWA";
  SearchIsoCountryCode2["Caf"] = "CAF";
  SearchIsoCountryCode2["Can"] = "CAN";
  SearchIsoCountryCode2["Cck"] = "CCK";
  SearchIsoCountryCode2["Che"] = "CHE";
  SearchIsoCountryCode2["Chl"] = "CHL";
  SearchIsoCountryCode2["Chn"] = "CHN";
  SearchIsoCountryCode2["Civ"] = "CIV";
  SearchIsoCountryCode2["Cmr"] = "CMR";
  SearchIsoCountryCode2["Cod"] = "COD";
  SearchIsoCountryCode2["Cog"] = "COG";
  SearchIsoCountryCode2["Cok"] = "COK";
  SearchIsoCountryCode2["Col"] = "COL";
  SearchIsoCountryCode2["Com"] = "COM";
  SearchIsoCountryCode2["Cpv"] = "CPV";
  SearchIsoCountryCode2["Cri"] = "CRI";
  SearchIsoCountryCode2["Cub"] = "CUB";
  SearchIsoCountryCode2["Cuw"] = "CUW";
  SearchIsoCountryCode2["Cxr"] = "CXR";
  SearchIsoCountryCode2["Cym"] = "CYM";
  SearchIsoCountryCode2["Cyp"] = "CYP";
  SearchIsoCountryCode2["Cze"] = "CZE";
  SearchIsoCountryCode2["Deu"] = "DEU";
  SearchIsoCountryCode2["Dji"] = "DJI";
  SearchIsoCountryCode2["Dma"] = "DMA";
  SearchIsoCountryCode2["Dnk"] = "DNK";
  SearchIsoCountryCode2["Dom"] = "DOM";
  SearchIsoCountryCode2["Dza"] = "DZA";
  SearchIsoCountryCode2["Ecu"] = "ECU";
  SearchIsoCountryCode2["Egy"] = "EGY";
  SearchIsoCountryCode2["Eri"] = "ERI";
  SearchIsoCountryCode2["Esh"] = "ESH";
  SearchIsoCountryCode2["Esp"] = "ESP";
  SearchIsoCountryCode2["Est"] = "EST";
  SearchIsoCountryCode2["Eth"] = "ETH";
  SearchIsoCountryCode2["Fin"] = "FIN";
  SearchIsoCountryCode2["Fji"] = "FJI";
  SearchIsoCountryCode2["Flk"] = "FLK";
  SearchIsoCountryCode2["Fra"] = "FRA";
  SearchIsoCountryCode2["Fro"] = "FRO";
  SearchIsoCountryCode2["Fsm"] = "FSM";
  SearchIsoCountryCode2["Gab"] = "GAB";
  SearchIsoCountryCode2["Gbr"] = "GBR";
  SearchIsoCountryCode2["Geo"] = "GEO";
  SearchIsoCountryCode2["Ggy"] = "GGY";
  SearchIsoCountryCode2["Gha"] = "GHA";
  SearchIsoCountryCode2["Gib"] = "GIB";
  SearchIsoCountryCode2["Gin"] = "GIN";
  SearchIsoCountryCode2["Glp"] = "GLP";
  SearchIsoCountryCode2["Gmb"] = "GMB";
  SearchIsoCountryCode2["Gnb"] = "GNB";
  SearchIsoCountryCode2["Gnq"] = "GNQ";
  SearchIsoCountryCode2["Grc"] = "GRC";
  SearchIsoCountryCode2["Grd"] = "GRD";
  SearchIsoCountryCode2["Grl"] = "GRL";
  SearchIsoCountryCode2["Gtm"] = "GTM";
  SearchIsoCountryCode2["Guf"] = "GUF";
  SearchIsoCountryCode2["Gum"] = "GUM";
  SearchIsoCountryCode2["Guy"] = "GUY";
  SearchIsoCountryCode2["Hkg"] = "HKG";
  SearchIsoCountryCode2["Hmd"] = "HMD";
  SearchIsoCountryCode2["Hnd"] = "HND";
  SearchIsoCountryCode2["Hrv"] = "HRV";
  SearchIsoCountryCode2["Hti"] = "HTI";
  SearchIsoCountryCode2["Hun"] = "HUN";
  SearchIsoCountryCode2["Idn"] = "IDN";
  SearchIsoCountryCode2["Imn"] = "IMN";
  SearchIsoCountryCode2["Ind"] = "IND";
  SearchIsoCountryCode2["Iot"] = "IOT";
  SearchIsoCountryCode2["Irl"] = "IRL";
  SearchIsoCountryCode2["Irn"] = "IRN";
  SearchIsoCountryCode2["Irq"] = "IRQ";
  SearchIsoCountryCode2["Isl"] = "ISL";
  SearchIsoCountryCode2["Isr"] = "ISR";
  SearchIsoCountryCode2["Ita"] = "ITA";
  SearchIsoCountryCode2["Jam"] = "JAM";
  SearchIsoCountryCode2["Jey"] = "JEY";
  SearchIsoCountryCode2["Jor"] = "JOR";
  SearchIsoCountryCode2["Jpn"] = "JPN";
  SearchIsoCountryCode2["Kaz"] = "KAZ";
  SearchIsoCountryCode2["Ken"] = "KEN";
  SearchIsoCountryCode2["Kgz"] = "KGZ";
  SearchIsoCountryCode2["Khm"] = "KHM";
  SearchIsoCountryCode2["Kir"] = "KIR";
  SearchIsoCountryCode2["Kna"] = "KNA";
  SearchIsoCountryCode2["Kor"] = "KOR";
  SearchIsoCountryCode2["Kwt"] = "KWT";
  SearchIsoCountryCode2["Lao"] = "LAO";
  SearchIsoCountryCode2["Lbn"] = "LBN";
  SearchIsoCountryCode2["Lbr"] = "LBR";
  SearchIsoCountryCode2["Lby"] = "LBY";
  SearchIsoCountryCode2["Lca"] = "LCA";
  SearchIsoCountryCode2["Lie"] = "LIE";
  SearchIsoCountryCode2["Lka"] = "LKA";
  SearchIsoCountryCode2["Lso"] = "LSO";
  SearchIsoCountryCode2["Ltu"] = "LTU";
  SearchIsoCountryCode2["Lux"] = "LUX";
  SearchIsoCountryCode2["Lva"] = "LVA";
  SearchIsoCountryCode2["Mac"] = "MAC";
  SearchIsoCountryCode2["Maf"] = "MAF";
  SearchIsoCountryCode2["Mar"] = "MAR";
  SearchIsoCountryCode2["Mco"] = "MCO";
  SearchIsoCountryCode2["Mda"] = "MDA";
  SearchIsoCountryCode2["Mdg"] = "MDG";
  SearchIsoCountryCode2["Mdv"] = "MDV";
  SearchIsoCountryCode2["Mex"] = "MEX";
  SearchIsoCountryCode2["Mhl"] = "MHL";
  SearchIsoCountryCode2["Mkd"] = "MKD";
  SearchIsoCountryCode2["Mli"] = "MLI";
  SearchIsoCountryCode2["Mlt"] = "MLT";
  SearchIsoCountryCode2["Mmr"] = "MMR";
  SearchIsoCountryCode2["Mne"] = "MNE";
  SearchIsoCountryCode2["Mng"] = "MNG";
  SearchIsoCountryCode2["Mnp"] = "MNP";
  SearchIsoCountryCode2["Moz"] = "MOZ";
  SearchIsoCountryCode2["Mrt"] = "MRT";
  SearchIsoCountryCode2["Msr"] = "MSR";
  SearchIsoCountryCode2["Mtq"] = "MTQ";
  SearchIsoCountryCode2["Mus"] = "MUS";
  SearchIsoCountryCode2["Mwi"] = "MWI";
  SearchIsoCountryCode2["Mys"] = "MYS";
  SearchIsoCountryCode2["Myt"] = "MYT";
  SearchIsoCountryCode2["Nam"] = "NAM";
  SearchIsoCountryCode2["Ncl"] = "NCL";
  SearchIsoCountryCode2["Ner"] = "NER";
  SearchIsoCountryCode2["Nfk"] = "NFK";
  SearchIsoCountryCode2["Nga"] = "NGA";
  SearchIsoCountryCode2["Nic"] = "NIC";
  SearchIsoCountryCode2["Niu"] = "NIU";
  SearchIsoCountryCode2["Nld"] = "NLD";
  SearchIsoCountryCode2["Nor"] = "NOR";
  SearchIsoCountryCode2["Npl"] = "NPL";
  SearchIsoCountryCode2["Nru"] = "NRU";
  SearchIsoCountryCode2["Nzl"] = "NZL";
  SearchIsoCountryCode2["Omn"] = "OMN";
  SearchIsoCountryCode2["Pak"] = "PAK";
  SearchIsoCountryCode2["Pan"] = "PAN";
  SearchIsoCountryCode2["Pcn"] = "PCN";
  SearchIsoCountryCode2["Per"] = "PER";
  SearchIsoCountryCode2["Phl"] = "PHL";
  SearchIsoCountryCode2["Plw"] = "PLW";
  SearchIsoCountryCode2["Png"] = "PNG";
  SearchIsoCountryCode2["Pol"] = "POL";
  SearchIsoCountryCode2["Pri"] = "PRI";
  SearchIsoCountryCode2["Prk"] = "PRK";
  SearchIsoCountryCode2["Prt"] = "PRT";
  SearchIsoCountryCode2["Pry"] = "PRY";
  SearchIsoCountryCode2["Pse"] = "PSE";
  SearchIsoCountryCode2["Pyf"] = "PYF";
  SearchIsoCountryCode2["Qat"] = "QAT";
  SearchIsoCountryCode2["Reu"] = "REU";
  SearchIsoCountryCode2["Rou"] = "ROU";
  SearchIsoCountryCode2["Rus"] = "RUS";
  SearchIsoCountryCode2["Rwa"] = "RWA";
  SearchIsoCountryCode2["Sau"] = "SAU";
  SearchIsoCountryCode2["Sdn"] = "SDN";
  SearchIsoCountryCode2["Sen"] = "SEN";
  SearchIsoCountryCode2["Sgp"] = "SGP";
  SearchIsoCountryCode2["Sgs"] = "SGS";
  SearchIsoCountryCode2["Shn"] = "SHN";
  SearchIsoCountryCode2["Sjm"] = "SJM";
  SearchIsoCountryCode2["Slb"] = "SLB";
  SearchIsoCountryCode2["Sle"] = "SLE";
  SearchIsoCountryCode2["Slv"] = "SLV";
  SearchIsoCountryCode2["Smr"] = "SMR";
  SearchIsoCountryCode2["Som"] = "SOM";
  SearchIsoCountryCode2["Spm"] = "SPM";
  SearchIsoCountryCode2["Srb"] = "SRB";
  SearchIsoCountryCode2["Ssd"] = "SSD";
  SearchIsoCountryCode2["Stp"] = "STP";
  SearchIsoCountryCode2["Sur"] = "SUR";
  SearchIsoCountryCode2["Svk"] = "SVK";
  SearchIsoCountryCode2["Svn"] = "SVN";
  SearchIsoCountryCode2["Swe"] = "SWE";
  SearchIsoCountryCode2["Swz"] = "SWZ";
  SearchIsoCountryCode2["Sxm"] = "SXM";
  SearchIsoCountryCode2["Syc"] = "SYC";
  SearchIsoCountryCode2["Syr"] = "SYR";
  SearchIsoCountryCode2["Tca"] = "TCA";
  SearchIsoCountryCode2["Tcd"] = "TCD";
  SearchIsoCountryCode2["Tgo"] = "TGO";
  SearchIsoCountryCode2["Tha"] = "THA";
  SearchIsoCountryCode2["Tjk"] = "TJK";
  SearchIsoCountryCode2["Tkl"] = "TKL";
  SearchIsoCountryCode2["Tkm"] = "TKM";
  SearchIsoCountryCode2["Tls"] = "TLS";
  SearchIsoCountryCode2["Ton"] = "TON";
  SearchIsoCountryCode2["Tto"] = "TTO";
  SearchIsoCountryCode2["Tun"] = "TUN";
  SearchIsoCountryCode2["Tur"] = "TUR";
  SearchIsoCountryCode2["Tuv"] = "TUV";
  SearchIsoCountryCode2["Twn"] = "TWN";
  SearchIsoCountryCode2["Tza"] = "TZA";
  SearchIsoCountryCode2["Uga"] = "UGA";
  SearchIsoCountryCode2["Ukr"] = "UKR";
  SearchIsoCountryCode2["Umi"] = "UMI";
  SearchIsoCountryCode2["Ury"] = "URY";
  SearchIsoCountryCode2["Usa"] = "USA";
  SearchIsoCountryCode2["Uzb"] = "UZB";
  SearchIsoCountryCode2["Vat"] = "VAT";
  SearchIsoCountryCode2["Vct"] = "VCT";
  SearchIsoCountryCode2["Ven"] = "VEN";
  SearchIsoCountryCode2["Vgb"] = "VGB";
  SearchIsoCountryCode2["Vir"] = "VIR";
  SearchIsoCountryCode2["Vnm"] = "VNM";
  SearchIsoCountryCode2["Vut"] = "VUT";
  SearchIsoCountryCode2["Wlf"] = "WLF";
  SearchIsoCountryCode2["Wsm"] = "WSM";
  SearchIsoCountryCode2["Yem"] = "YEM";
  SearchIsoCountryCode2["Zaf"] = "ZAF";
  SearchIsoCountryCode2["Zmb"] = "ZMB";
  SearchIsoCountryCode2["Zwe"] = "ZWE";
  return SearchIsoCountryCode2;
})(SearchIsoCountryCode || {});
var SearchLocationType = /* @__PURE__ */ ((SearchLocationType2) => {
  SearchLocationType2["Airport"] = "AIRPORT";
  SearchLocationType2["FerryStation"] = "FERRY_STATION";
  SearchLocationType2["RailwayStation"] = "RAILWAY_STATION";
  return SearchLocationType2;
})(SearchLocationType || {});
var SearchOutletCategory = /* @__PURE__ */ ((SearchOutletCategory2) => {
  SearchOutletCategory2["Eat"] = "EAT";
  SearchOutletCategory2["FastTrack"] = "FAST_TRACK";
  SearchOutletCategory2["Lounge"] = "LOUNGE";
  SearchOutletCategory2["Rail"] = "RAIL";
  SearchOutletCategory2["Refresh"] = "REFRESH";
  SearchOutletCategory2["Rest"] = "REST";
  SearchOutletCategory2["Retail"] = "RETAIL";
  SearchOutletCategory2["Unwind"] = "UNWIND";
  return SearchOutletCategory2;
})(SearchOutletCategory || {});
var SearchOutletRegion = /* @__PURE__ */ ((SearchOutletRegion2) => {
  SearchOutletRegion2["Americas"] = "AMERICAS";
  SearchOutletRegion2["Apac"] = "APAC";
  SearchOutletRegion2["Emea"] = "EMEA";
  SearchOutletRegion2["Global"] = "GLOBAL";
  return SearchOutletRegion2;
})(SearchOutletRegion || {});
var SearchType = /* @__PURE__ */ ((SearchType2) => {
  SearchType2["Airport"] = "AIRPORT";
  SearchType2["AirportDepDatetime"] = "AIRPORT_DEP_DATETIME";
  SearchType2["Flight"] = "FLIGHT";
  SearchType2["FlightManual"] = "FLIGHT_MANUAL";
  return SearchType2;
})(SearchType || {});
var SlotState = /* @__PURE__ */ ((SlotState2) => {
  SlotState2["Cancelled"] = "CANCELLED";
  SlotState2["Confirmed"] = "CONFIRMED";
  SlotState2["Holding"] = "HOLDING";
  return SlotState2;
})(SlotState || {});
var SmokingPolicy = /* @__PURE__ */ ((SmokingPolicy2) => {
  SmokingPolicy2["SmokingAreaInsideLounge"] = "smokingAreaInsideLounge";
  SmokingPolicy2["SmokingRoomInsideLounge"] = "smokingRoomInsideLounge";
  return SmokingPolicy2;
})(SmokingPolicy || {});
var SortOrder = /* @__PURE__ */ ((SortOrder2) => {
  SortOrder2["Asc"] = "ASC";
  SortOrder2["Desc"] = "DESC";
  return SortOrder2;
})(SortOrder || {});
var Status = /* @__PURE__ */ ((Status2) => {
  Status2["Active"] = "ACTIVE";
  Status2["Inactive"] = "INACTIVE";
  Status2["StopSale"] = "STOP_SALE";
  return Status2;
})(Status || {});
var StripeUiMode = /* @__PURE__ */ ((StripeUiMode2) => {
  StripeUiMode2["Embedded"] = "embedded";
  StripeUiMode2["Hosted"] = "hosted";
  return StripeUiMode2;
})(StripeUiMode || {});
var Theme = /* @__PURE__ */ ((Theme2) => {
  Theme2["PriorityPass"] = "PRIORITY_PASS";
  return Theme2;
})(Theme || {});
var Tier = /* @__PURE__ */ ((Tier2) => {
  Tier2["Black"] = "BLACK";
  Tier2["Gold"] = "GOLD";
  Tier2["LowCost"] = "LOW_COST";
  Tier2["Private"] = "PRIVATE";
  return Tier2;
})(Tier || {});
var TimezoneType = /* @__PURE__ */ ((TimezoneType2) => {
  TimezoneType2["Local"] = "LOCAL";
  TimezoneType2["Utc"] = "UTC";
  return TimezoneType2;
})(TimezoneType || {});
var TripSource = /* @__PURE__ */ ((TripSource2) => {
  TripSource2["MobileApp"] = "MOBILE_APP";
  return TripSource2;
})(TripSource || {});
var TripState = /* @__PURE__ */ ((TripState2) => {
  TripState2["Completed"] = "COMPLETED";
  TripState2["Deleted"] = "DELETED";
  TripState2["Upcoming"] = "UPCOMING";
  return TripState2;
})(TripState || {});
var VariationType = /* @__PURE__ */ ((VariationType2) => {
  VariationType2["Annual"] = "ANNUAL";
  VariationType2["DateSpecific"] = "DATE_SPECIFIC";
  return VariationType2;
})(VariationType || {});
var OutletIdType = /* @__PURE__ */ ((OutletIdType2) => {
  OutletIdType2["Id"] = "Id";
  OutletIdType2["LegacyCode"] = "legacyCode";
  OutletIdType2["SalesforceId"] = "salesforceID";
  return OutletIdType2;
})(OutletIdType || {});
var AcceptInvitationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AcceptInvitation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptInvitationInput" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AcceptInvitationInput" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptInvitation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "acceptInvitationInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "acceptInvitationInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inviteeEmail" }
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var ConfirmAmendmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "confirmAmendment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "amendmentInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AmendmentInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmAmendment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "amendmentInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "amendmentInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentOption" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refundStatus" }
                },
                { kind: "Field", name: { kind: "Name", value: "refundedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var CancelBookingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "cancelBooking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cancelBookingId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelBooking" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "cancelBookingId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var CheckinBookingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CheckinBooking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkinBookingId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkinBooking" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "checkinBookingId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var ConfirmBookingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConfirmBooking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "confirmBookingId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmBooking" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "confirmBookingId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var CreateBookingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateBooking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookingInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BookingInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createBooking" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookingInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookingInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "productID" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedEntitlementsCount" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var DeclineBookingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineBooking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "declineBookingId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "declineBooking" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "declineBookingId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var DeleteBookingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteBooking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deleteBookingId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteBooking" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deleteBookingId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var FindOrCreateConsumerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FindOrCreateConsumer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consumerInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ConsumerInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findOrCreateConsumer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consumerInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consumerInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var LinkAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LinkAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "linkedAccountInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "LinkedAccountInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "linkAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "linkedAccountInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "linkedAccountInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "externalID" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateOfBirth" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailAddress" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      { kind: "Field", name: { kind: "Name", value: "crmId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkedAccounts" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "provider" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membershipID" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membershipType" }
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var PublishOutletDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PublishOutlet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "publishOutletId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "publishOutlet" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "publishOutletId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var RevertOutletDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RevertOutlet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "revertOutletId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "revertOutlet" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "revertOutletId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var UpdateConsumerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateConsumer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consumerInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ConsumerInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateConsumer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consumerInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consumerInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var UpdateOutletDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOutlet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "updateOutletId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "outletInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OutletInput" }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "outletContentInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OutletContentInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateOutlet" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "updateOutletId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "outletInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "outletInput" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "outletContentInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "outletContentInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var ForecastDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Forecast" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loungeBusynessInput" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "LoungeBusynessInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "forecast" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "loungeBusynessInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "loungeBusynessInput" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "level" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetAvailableSlotsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAvailableSlots" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AvailabilityInput" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAvailableSlots" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "slots" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maxDuration" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "slotConfigurationId" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetBookingByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBookingById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "getBookingById" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getBookingByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "getBookingById" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "actingAccount" }
                },
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                { kind: "Field", name: { kind: "Name", value: "lastArrival" } },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                { kind: "Field", name: { kind: "Name", value: "reference" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price_currency" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guestAdultCount" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guestChildrenCount" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guestInfantCount" }
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailAddress" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "loungeName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openingHours" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "altText" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contentType" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "height" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "airportName" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "terminal" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "reservationOnlyFee"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reservationCost" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refundStatus" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedEntitlementsCount" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetBookingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBookings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BookingStatus" }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "experienceId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getBookings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "experienceID" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "experienceId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } },
                { kind: "Field", name: { kind: "Name", value: "bookedTo" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "metadata" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "reference" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guestAdultCount" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guestChildrenCount" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "guestInfantCount" }
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consumer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailAddress" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experience" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "loungeName" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetBookingsOverviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBookingsOverview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BookingStatus" }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "experienceId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getBookings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "experienceID" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "experienceId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bookedFrom" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetConsumerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetConsumer" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getConsumer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "crmId" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailAddress" }
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetConsumerByEmailAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetConsumerByEmailAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "emailAddress" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getConsumerByEmailAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailAddress" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "emailAddress" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetConsumerByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetConsumerByID" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "getConsumerById" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getConsumerByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "getConsumerById" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "linkedAccounts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "membershipID" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "membershipType" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "provider" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "analytics" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "externalID" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "memberships" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "membershipNumber" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "dateOfBirth" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailAddress" }
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "locale" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetEntitlementsForProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetEntitlementsForProduct" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "productId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookingEnd" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookingStart" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "membershipID" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "retry" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getEntitlementsForProduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "productId" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookingEnd" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookingEnd" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookingStart" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookingStart" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "membershipID" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "membershipID" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "retry" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "retry" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "expiryDate" } },
                { kind: "Field", name: { kind: "Name", value: "expired" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "redeemed" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetExperienceByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetExperienceByID" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "getExperienceById" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getExperienceByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "getExperienceById" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "loungeName" } },
                { kind: "Field", name: { kind: "Name", value: "loungeCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "airportName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "airportCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terminal" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terminalCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isoCountryCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lbCountryCode" }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetFlightDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFlightDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "flightDetails" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "FlightDetailsInput" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFlightDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "flightDetails" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "flightDetails" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "arrival" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "airport" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terminal" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateTime" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "local" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "utc" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "departure" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "airport" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terminal" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateTime" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "local" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "utc" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetInvitationByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetInvitationByID" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "getInvitationById" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getInvitationByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "getInvitationById" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inviteeEmail" }
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetLocationDescriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLocationDescription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "locationDetails" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "OutletLocationDetailsInput" }
            }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isLandside" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getLocationDescription" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "locationDetails" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "locationDetails" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isLandside" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isLandside" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
};
var GetOutletDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOutlet" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "getOutletId" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "outletIdType" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "outletIDType" }
            }
          }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "additionalFilters" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ProductFilters" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOutlet" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "outletIDType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "outletIdType" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "additionalFilters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "additionalFilters" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "getOutletId" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "maxGuests" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maxStayHours" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "draft" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessTimes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accessHours" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "monday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tuesday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "wednesday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "thursday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "friday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "saturday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sunday" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "exceptions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "accessHours"
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "startTime"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "endTime"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "date" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDate" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDate" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "exceptionType"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isOpen" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "priority" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isRecurring"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "peakHours" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "allowedSmokingPolicy"
                              }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "childrenAdmittedFreeNumber"
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "maximum" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "per" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "childrenAdmittedFreeAge"
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "from" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "to" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "childrenAllowed" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "childrenMustBeAccompaniedUnderAge"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dressCodeOptions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "noAboveKneeSkirtsOrDresses"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "noBaseballCaps"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "noSandals" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "noShorts" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "noSleevelessTops"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "noSportswear"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "noTShirts" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "smartCasual"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasDressCode" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "minimumChildAge" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "smokingAllowed" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditions" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locationDetails" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "facilities" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isAvailable" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "pointOfInterestID"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "city" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isoCountryCode" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "landside" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latitude" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "longitude" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "terminal" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timezone" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locationDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "outletZone" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "passportControl" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gateNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gateNumberTwo" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "gateLocationDirection"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "concourse" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "directions" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeStairs"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeLift"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeEscalator"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeBus"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeShuttle"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeTrain"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "floor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "directions" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeStairs"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeLift"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeEscalator"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeBus"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeShuttle"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeTrain"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "level" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "directions" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeStairs"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeLift"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeEscalator"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeBus"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeShuttle"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "takeTrain"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pier" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "satellite" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "zone" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "module" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "letterOrNumber"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "dutyFreeDirection"
                              }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "foodCourtDirection"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "foodCourtName" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasSignName" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signName" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "media" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "asset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "contentType"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fileName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "height"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "width"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastEdited"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "editor"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName"
                                                }
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName"
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "asset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "contentType"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fileName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "height"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "width"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastEdited"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "editor"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName"
                                                }
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName"
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "products" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accessType" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "category" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cost" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "costCurrency"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "defaultTaxPercentage"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "programme" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "projectedCost"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "reservationCost"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ppStripeID" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salePrices" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "programme" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "salePrice" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "salePriceCurrency"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "stripePriceID"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salesforceID" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "stage" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tier" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sectionsMeta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accessTimes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organisation"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edited" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "importantInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organisation"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edited" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organisation"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edited" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "facilities" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organisation"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edited" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "location" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organisation"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edited" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "media" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "organisation"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edited" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "editedSections" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "versionStatus" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "salesforceID" }
                },
                { kind: "Field", name: { kind: "Name", value: "category" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "legacyCode" } },
                { kind: "Field", name: { kind: "Name", value: "category" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "passengerTypes" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerBrand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "partnerIntegrationData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "partnerIntegrationID"
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "importantInformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "facilities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isAvailable" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timezone" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isoCountryCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "landside" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "latitude" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "longitude" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terminal" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "locationDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outletZone" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "passportControl" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gateNumber" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gateNumberTwo" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gateLocationDirection" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "concourse" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "directions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeStairs" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeLift" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "takeEscalator"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeBus" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "takeShuttle"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeTrain" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "floor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "directions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeStairs" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeLift" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "takeEscalator"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeBus" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "takeShuttle"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeTrain" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "level" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "directions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeStairs" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeLift" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "takeEscalator"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeBus" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "takeShuttle"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "takeTrain" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pier" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "satellite" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "zone" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "module" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "letterOrNumber" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dutyFreeDirection" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "foodCourtDirection" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "foodCourtName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasSignName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signName" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "editor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organisation" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastEdited" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ancillaryProducts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "tier" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cost" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costCurrency" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "programme" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultTaxPercentage"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "projectedCost" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reservationCost" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salePrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "programme" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salePrice" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "salePriceCurrency"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "stripePriceID" }
                            }
                          ]
                        }
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salesforceID" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "products" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "tier" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "costs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cost" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "costCurrency" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "programme" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "defaultTaxPercentage"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "projectedCost" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reservationCost" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "partnerIntegrationData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "partnerProductID" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salePrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "programme" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salePrice" }
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "salePriceCurrency"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "stripePriceID" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessType" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salesforceID" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "stage" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "editedSections" }
                },
                { kind: "Field", name: { kind: "Name", value: "tier" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedSmokingPolicy" }
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "childrenAdmittedFreeNumber"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "maximum" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "per" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "childrenAdmittedFreeAge"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "from" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "to" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "childrenAllowed" }
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "childrenMustBeAccompaniedUnderAge"
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dressCodeOptions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "noAboveKneeSkirtsOrDresses"
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noBaseballCaps" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noSandals" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noShorts" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noSleevelessTops" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noSportswear" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "noTShirts" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "smartCasual" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasDressCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minimumChildAge" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "smokingAllowed" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditions" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locationDetails" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "accessTimes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessHours" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "monday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tuesday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "wednesday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "thursday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "friday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "saturday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sunday" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "peakHours" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startTime" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endTime" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "exceptions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accessHours" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startDate" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endDate" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "date" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "exceptionType" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isOpen" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "priority" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isRecurring" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "media" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mainImage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "asset" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "contentType"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fileName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "height" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "width" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metadata" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "asset" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "contentType"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description"
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fileName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "height" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "url" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "width" }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "metadata" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastEdited" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "editor" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "salesforceID" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reservationEmail" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerBrand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sectionsMeta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edited" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "media" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edited" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessTimes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edited" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edited" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "importantInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edited" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "facilities" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" }
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "organisation"
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEdited" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "edited" }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetOutletsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOutlets" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OutletAndProductFilters" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOutlets" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "page" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "draft" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "media" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mainImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "asset"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url"
                                                }
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description"
                                                }
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title"
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "asset"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "url"
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "terminal" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "partnerBrand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "media" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "asset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "asset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "contentType"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title"
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentPage" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasPreviousPage" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalItemCount" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetOutletsCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetOutletsCount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OutletAndProductFilters" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getOutlets" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalItemCount" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetPartnerBrandByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPartnerBrandByID" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPartnerBrandByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "outlets" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyCode" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "terminal" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "category" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "media" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "asset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastEdited"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "editor"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName"
                                                }
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName"
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "asset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "url" }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title"
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastEdited"
                                          }
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "editor"
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "firstName"
                                                }
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "lastName"
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetPartnerBrandsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPartnerBrands" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PartnerBrandFilters" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPartnerBrands" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "page" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outlets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentPage" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasPreviousPage" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalItemCount" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetPartnerBrandsCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPartnerBrandsCount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PartnerBrandFilters" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPartnerBrands" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalItemCount" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var GetPartnerByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPartnerByID" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "getPartnerById" }
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPartnerByID" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "getPartnerById" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailAddress" }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var SearchExperiencesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchExperiences" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchFilter" }
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "SearchFilterInput" }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchExperiences" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "query" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "query" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchFilter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchFilter" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "loungeName" } },
                { kind: "Field", name: { kind: "Name", value: "loungeCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "location" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "airportName" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "airportCode" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terminal" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timezone" }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerIdProd" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerIdTest" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "partnerIntegrationId" }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pricing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricingType" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reservationCost" }
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "lifestyleXReservationCharge"
                        }
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "walkInCostCurrentPPRate"
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lifestyleXWalkInCharge" }
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "lifestyleXReservationCharge"
                        }
                      },
                      { kind: "Field", name: { kind: "Name", value: "vat" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reservationOnlyFeeCost" }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reservationOnlyFee" }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "facilities" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "openingHours" }
                },
                { kind: "Field", name: { kind: "Name", value: "conditions" } },
                { kind: "Field", name: { kind: "Name", value: "directions" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "altText" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" }
                      },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};
var IsInvitationTokenValidDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "IsInvitationTokenValid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inviteToken" }
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "isInvitationTokenValid" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "inviteToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inviteToken" }
                }
              }
            ]
          }
        ]
      }
    }
  ]
};
var SearchOutletsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchOutlets" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" }
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchFilters" }
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SearchFilterInput" }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchOutlets" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "query" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "query" }
                }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchFilters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchFilters" }
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "SearchOutletList" }
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legacyCode" }
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "location" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" }
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "terminal" }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
};

// src/index.ts
function randomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
function matchingTextColor(color) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1e3;
  return yiq >= 128 ? "#000" : "#fff";
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AcceptInvitationDocument,
  ActivationStatus,
  AddressType,
  AdmissionPer,
  AffiliateProgramme,
  AmendmentStatus,
  BookingFlowType,
  BookingStatus,
  BookingType,
  Brand,
  CancelBookingDocument,
  CatalogueProductType,
  CheckinBookingDocument,
  ConfirmAmendmentDocument,
  ConfirmBookingDocument,
  CostZone,
  CreateBookingDocument,
  Currency,
  DeclineBookingDocument,
  DeleteBookingDocument,
  EnrollmentStatus,
  ExceptionType,
  ExperienceCategory,
  ExperienceType,
  FakeDoorInterest,
  FakeDoorOs,
  FakeDoorType,
  FavouriteEntityType,
  FindOrCreateConsumerDocument,
  ForecastDocument,
  GetAvailableSlotsDocument,
  GetBookingByIdDocument,
  GetBookingsDocument,
  GetBookingsOverviewDocument,
  GetConsumerByEmailAddressDocument,
  GetConsumerByIdDocument,
  GetConsumerDocument,
  GetEntitlementsForProductDocument,
  GetExperienceByIdDocument,
  GetFlightDetailsDocument,
  GetInvitationByIdDocument,
  GetLocationDescriptionDocument,
  GetOutletDocument,
  GetOutletsCountDocument,
  GetOutletsDocument,
  GetPartnerBrandByIdDocument,
  GetPartnerBrandsCountDocument,
  GetPartnerBrandsDocument,
  GetPartnerByIdDocument,
  InvitationUserType,
  IsInvitationTokenValidDocument,
  IsoCountryCode,
  JourneyLeg,
  LinkAccountDocument,
  LinkedAccountProvider,
  LocationType,
  MembershipPlanStatus,
  OutletCategory,
  OutletIdType,
  OutletOpenStatus,
  OutletRegion,
  OutletStatus,
  OutletVersionStatus,
  PackageJourneyLeg,
  PartnerBrandStatus,
  PassengerType,
  PaymentCustomerStatus,
  PaymentOption,
  PaymentProvider,
  PaymentRefundPriority,
  PaymentRefundStatusResult,
  PaymentRefundType,
  PaymentStatusResult,
  PaymentType,
  PrimaryProductAccessType,
  ProductCategory,
  ProductCostType,
  ProductIdType,
  ProductStage,
  ProductStatus,
  ProductType,
  Programme,
  PublishOutletDocument,
  RefundStatus,
  RevertOutletDocument,
  SearchExperiencesDocument,
  SearchIsoCountryCode,
  SearchLocationType,
  SearchOutletCategory,
  SearchOutletRegion,
  SearchOutletsDocument,
  SearchType,
  SlotState,
  SmokingPolicy,
  SortOrder,
  Status,
  StripeUiMode,
  Theme,
  Tier,
  TimezoneType,
  TripSource,
  TripState,
  UpdateConsumerDocument,
  UpdateOutletDocument,
  VariationType,
  matchingTextColor,
  randomColor
});
