export const formatLanguageParam = (lang: string) => {
  if (!lang) return 'en';

  const splitLang = lang.split('');

  if (splitLang.length === 2) {
    return lang;
  }

  return splitLang.slice(0, 2).join('');
};
