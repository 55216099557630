import { Client } from '@collinsonx/constants/enums';
import {
  AbstractedFetchFunction,
  useLazyQuery,
} from '@collinsonx/utils/apollo';
import { Entitlement } from '@collinsonx/utils/generatedTypes/graphql';
import { getEntitlementsForProduct } from '@collinsonx/utils/queries';
import { BookingContext } from 'context/bookingContext';
import dayjs from 'dayjs';
import { useContext } from 'react';

import usePayload from './payload';
import useProduct from './useProduct';

export type FetchEntitlementsData = {
  getEntitlementsForProduct: Entitlement[];
};

export type FetchEntitlementsType =
  AbstractedFetchFunction<FetchEntitlementsData>;

interface UseEntitlements {
  entitlementsData?: FetchEntitlementsData;
  fetchEntitlements: () => void;
  loadingEntitlements: boolean;
}

const useLegacyMembershipID = dayjs().isBefore(dayjs('2025-01-01'));

const useEntitlements = (): UseEntitlements => {
  const { productID } = useProduct();
  const { linkedAccountId, membership, membershipType, payload } = usePayload();
  const { booking } = useContext(BookingContext);

  const { accountProvider } = payload || {};
  const programme = linkedAccountId ? accountProvider : null;
  const membershipID = useLegacyMembershipID ? linkedAccountId : membership?.id;
  const { departureDate } = booking || {};

  const validateFetchEntitlements = () => {
    if (useLegacyMembershipID && membershipType !== Client.AMEX_UK) return;

    fetchEntitlements();
  };

  const [
    fetchEntitlements,
    { data: entitlementsData, loading: loadingEntitlements },
  ] = useLazyQuery<{
    getEntitlementsForProduct: Entitlement[];
  }>(getEntitlementsForProduct, {
    variables: {
      bookingEnd: departureDate,
      membershipID,
      productId: productID,
      programme,
      retry: false,
    },
  });

  return {
    entitlementsData,
    fetchEntitlements: validateFetchEntitlements,
    loadingEntitlements,
  };
};

export default useEntitlements;
