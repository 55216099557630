import { ForwardedRef, forwardRef } from 'react';
import { RenderElement } from 'types/components';

type DomNodeProps = {
  children: RenderElement;
};

const DomNode = forwardRef<HTMLDivElement, DomNodeProps>(
  ({ children }: DomNodeProps, ref: ForwardedRef<HTMLDivElement>) => (
    <span ref={ref}>{children}</span>
  )
);

export default DomNode;
