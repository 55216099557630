import { Button, ButtonProps } from '@collinsonx/design-system/core';
import { logAction, sendMobileEvent } from '@lib';
import { MOBILE_ACTION_BACK } from 'config/constants';
import usePayload from 'hooks/payload';
import { useCallback } from 'react';

type BackButtonProps = {
  analyticsTag?: string;
};

export default function BackButton({
  ...props
}: BackButtonProps & ButtonProps) {
  const { referrerUrl } = usePayload();
  const handleClick = useCallback(() => {
    logAction('backbutton', props.analyticsTag ?? '');
    if (top) {
      if (referrerUrl) {
        top.location.href = referrerUrl;
      } else {
        const windowObj: any = window;
        sendMobileEvent(windowObj, MOBILE_ACTION_BACK);
      }
    }
  }, [referrerUrl]);
  return (
    <Button
      {...props}
      onClick={handleClick}
      style={{
        background: 'var(--white)',
        border: '1px solid var(--button-bg)',
        color: 'var(--button-bg)',
      }}
    />
  );
}
