import { Box, Container, Flex } from '@collinsonx/design-system/core';
import usePayload from 'hooks/payload';
import { forwardRef, ReactNode, Ref } from 'react';
import { RenderElement } from 'types/components';

import AppLogo from '../AppLogo';
import classes from './Layout.module.css';

interface LayoutProps {
  bottomNavigation?: RenderElement;
  children: ReactNode;
}
type ContainerRef = Ref<HTMLDivElement> | undefined;

const Layout = forwardRef(
  ({ bottomNavigation, children }: LayoutProps, ref: ContainerRef) => {
    const { payload } = usePayload();

    return (
      <Container className={classes.container} fluid px={0} ref={ref}>
        <Box className={classes.containerL2}>
          <Box className={classes.logoWrapper} mb={2} mt={2}>
            {payload && (
              <AppLogo
                accountProvider={payload.accountProvider}
                membershipType={payload.membershipType}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.contentWrapper}>{children}</Box>
        {bottomNavigation && (
          <Flex className={classes.bottomNavigationWrapper}>
            <Flex className={classes.bottomNavigationContainer}>
              {bottomNavigation}
            </Flex>
          </Flex>
        )}
      </Container>
    );
  }
);

export default Layout;
